import * as React from 'react';
import styled from 'styled-components';
import { flexGap, verticalSpace } from '@doltech/utils/lib/css-style';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { s3ToCDN } from '@doltech/utils/lib/url';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { SuggestionNotFoundButton } from './SuggestionNotFoundButton';
import {
  GrammarTemplateTypeEnum,
} from '../shared/constants';
import {
  findGrammarUrlByTemplateTypeId,
  getFinalUrlGrammar,
} from '../shared/utils';
import { environment } from '@doltech/core/lib/environment/environment';

const Container = styled.div`
  padding: 140px 16px;
  margin: 0 auto;

  display: grid;
  grid-auto-flow: row;
  grid-template-columns: minmax(0, 640px);
  align-items: center;
  justify-content: center;
  ${verticalSpace(16)};

  .text {
    text-align: center;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;

  .image {
    width: 180px;
    height: 180px;
  }
`;

const Content = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${verticalSpace(16)};
`;

const HighlightTextWithLink = styled.a`
  color: ${colorsV2.purple100};
  border-bottom: 1px solid ${colorsV2.purple100};

  &:hover {
    cursor: pointer;
    color: ${colorsV2.purple80};
    border-bottom: 1px solid ${colorsV2.purple80};
  }
`;

const List = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  ${flexGap([20, 20])};
`;

interface GrammarLandingNotFound404Props {
  otherUrlInfo?: any;
}

export const GrammarLandingNotFound404 = (props: GrammarLandingNotFound404Props) => {
  const { otherUrlInfo } = props;

  const rawTheoryUrl = findGrammarUrlByTemplateTypeId(otherUrlInfo, GrammarTemplateTypeEnum.LANDING_GRAMMAR_THEORY);
  const rawExerciseUrl = findGrammarUrlByTemplateTypeId(otherUrlInfo, GrammarTemplateTypeEnum.LANDING_GRAMMAR_EXERCISE);

  return (
    <Container>
      <ImageWrapper>
        <div className="image">
          <img
            src={s3ToCDN(
              'https://dol-english-resource-dev.s3.ap-southeast-1.amazonaws.com/directus-upload/ced73f8d-ee14-4285-8bbd-e3fe1a572296.png',
            )}
            alt="empty-state"
          />
        </div>
      </ImageWrapper>
      <Content>
        <ResponsiveTypography.Title level={1} variant="semi-bold/32-40" color="black100">
          Trang bạn tìm kiếm không tồn tại
        </ResponsiveTypography.Title>
        <ResponsiveTypography.Paragraph variant="regular/14-20" color="gray120">
          Bạn có thể tìm hiểu các nội dung khác của DOL Grammar dưới đây hoặc trở về {' '}
          <HighlightTextWithLink href={environment.REACT_APP_DOL_GRAMMAR_WEBSITE_HOST_NAME}>
            <ResponsiveTypography.Text>
              Trang chủ
            </ResponsiveTypography.Text>
          </HighlightTextWithLink>
        </ResponsiveTypography.Paragraph>
        <List>
          <SuggestionNotFoundButton
            label="Lý thuyết Ngữ pháp"
            href={getFinalUrlGrammar(rawTheoryUrl)}
          />
          <SuggestionNotFoundButton
            label="Bài tập Ngữ pháp"
            href={getFinalUrlGrammar(rawExerciseUrl)}
          />
          <SuggestionNotFoundButton
            label="Các thì"
            href={getFinalUrlGrammar(`${rawTheoryUrl}/thi-trong-tieng-anh`)}
          />
          <SuggestionNotFoundButton
            label="Danh từ"
            href={getFinalUrlGrammar(`${rawTheoryUrl}/danh-tu`)}
          />
          <SuggestionNotFoundButton
            label="Đại từ"
            href={getFinalUrlGrammar(`${rawTheoryUrl}/dai-tu`)}
          />
          <SuggestionNotFoundButton
            label="Động từ"
            href={getFinalUrlGrammar(`${rawTheoryUrl}/dong-tu`)}
          />
          <SuggestionNotFoundButton
            label="Trạng từ"
            href={getFinalUrlGrammar(`${rawTheoryUrl}/trang-tu`)}
          />
          <SuggestionNotFoundButton
            label="Tính từ"
            href={getFinalUrlGrammar(`${rawTheoryUrl}/tinh-tu`)}
          />
          <SuggestionNotFoundButton
            label="Liên từ"
            href={getFinalUrlGrammar(`${rawTheoryUrl}/lien-tu`)}
          />
          <SuggestionNotFoundButton
            label="Giới từ"
            href={getFinalUrlGrammar(`${rawTheoryUrl}/gioi-tu`)}
          />
          <SuggestionNotFoundButton
            label="Thán từ"
            href={getFinalUrlGrammar(`${rawTheoryUrl}/than-tu`)}
          />
          <SuggestionNotFoundButton
            label="Mạo từ"
            href={getFinalUrlGrammar(`${rawTheoryUrl}/mao-tu`)}
          />
          <SuggestionNotFoundButton
            label="Cấu trúc câu"
            href={getFinalUrlGrammar(`${rawTheoryUrl}/cau-truc-cau`)}
          />
          <SuggestionNotFoundButton
            label="Mệnh đề"
            href={getFinalUrlGrammar(`${rawTheoryUrl}/menh-de`)}
          />
          <SuggestionNotFoundButton
            label="Từ hạn định"
            href={getFinalUrlGrammar(`${rawTheoryUrl}/tu-han-dinh`)}
          />
        </List>
      </Content>
    </Container>
  );
};
