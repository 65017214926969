import { grammarService, publicGrammarService } from './client';
import { landingGrammarUrls } from './api-urls';

/** public grammar service **/

export const getLandingGrammarPageByTemplateTypes = async (templateTypes) => {
  const data = await publicGrammarService.get<any>(
    landingGrammarUrls.GET_LANDING_GRAMMAR_BY_TEMPLATE,
    {
      templateTypes,
    }
  );
  return data as any;
};

export const getGrammarSitemapsByTemplateTypes = async (templateTypeId) => {
  const data = await publicGrammarService.get<any>(
    landingGrammarUrls.GET_SITEMAPS_BY_TEMPLATE_TYPE,
    {
      templateTypeId,
    }
  );
  return data as any;
};

export const getLandingGrammarHomePageDetail = async () => {
  const data = await publicGrammarService.get<any>(landingGrammarUrls.GET_LANDING_BLOG_HOME_SEO);
  return data as any;
};
export const getSeoPageLandingGrammarByUrl = async (url) => {
  const data = await publicGrammarService.get<any>(landingGrammarUrls.GET_SEO_PAGE_BY_URL, {
    url,
  });
  return data as any;
};

export const getSeoPageSchemaGioiThieu = async () => {
  const data = await publicGrammarService.get<any>(landingGrammarUrls.GET_SEO_SCHEMA);
  return data as any;
};

export const getSeoPageLandingGrammarByPageId = async (pageId) => {
  const data = await publicGrammarService.get<any>(
    landingGrammarUrls.GET_SEO_PAGE_DETAIL_BY_PAGE_ID,
    {
      pageId,
    }
  );
  return data as any;
};

export const getSeoPageLandingGrammarById = async (id) => {
  const data = await publicGrammarService.get<any>(
    landingGrammarUrls.GET_LANDING_GRAMMAR_DETAIL_BY_ID,
    {
      id,
    }
  );
  return data as any;
};

export const getLandingGrammarTopicListing = async () => {
  const data = await publicGrammarService.get<any>(
    landingGrammarUrls.GET_LANDING_GRAMMAR_TOPIC_LISTING
  );
  return data as any;
};

export const getLandingGrammarTheoryListing = async (query) => {
  const data = await publicGrammarService.get<any>(
    landingGrammarUrls.GET_LANDING_GRAMMAR_THEORY_LISTING,
    query
  );
  return data as any;
};

export const getLandingGrammarExerciseListing = async (query) => {
  const data = await publicGrammarService.get<any>(
    landingGrammarUrls.GET_LANDING_GRAMMAR_EXERCISE_LISTING,
    query
  );
  return data as any;
};

export const getTheoryLatestLandingGrammar = async (query) => {
  const { data } = await publicGrammarService.get<any>(
    landingGrammarUrls.GET_LANDING_GRAMMAR_THEORY_LATEST,
    query
  );
  return data as any;
};

export const getExerciseLatestLandingGrammar = async (query) => {
  const { data } = await publicGrammarService.get<any>(
    landingGrammarUrls.GET_LANDING_GRAMMAR_EXERCISE_LATEST,
    query
  );
  return data as any;
};

export const getTheoryLatestLandingGrammarAuth = async (query) => {
  const { data } = await grammarService.get<any>(
    landingGrammarUrls.GET_LANDING_GRAMMAR_THEORY_LATEST,
    query
  );
  return data as any;
};

export const getExerciseLatestLandingGrammarAuth = async (query) => {
  const { data } = await grammarService.get<any>(
    landingGrammarUrls.GET_LANDING_GRAMMAR_EXERCISE_LATEST,
    query
  );
  return data as any;
};

export const getRelateExerciseLandingGrammar = async (query) => {
  const { data } = await publicGrammarService.get<any>(
    landingGrammarUrls.GET_RELATE_EXERCISE_BY_THEORY,
    query
  );
  return data as any;
};

/** grammar service **/

export const getLandingGrammarRecentExercisePosts = async (query) => {
  const { data } = await grammarService.get<any>(
    landingGrammarUrls.GET_LANDING_GRAMMAR_RECENT_EXERCISE_POSTS,
    query
  );
  return data as any;
};

export const getLandingGrammarRecentTheoryPosts = async (query) => {
  const { data } = await grammarService.get<any>(
    landingGrammarUrls.GET_LANDING_GRAMMAR_RECENT_THEORY_POSTS,
    query
  );
  return data as any;
};

export const getLandingGrammarExercisesAuthPaged = async (query) => {
  const { data } = await grammarService.get<any>(
    landingGrammarUrls.GET_LANDING_GRAMMAR_AUTH_EXERCISES_LISTING,
    query
  );
  return data as any;
};

export const getLandingGrammarTheoriesAuthPaged = async (query) => {
  const { data } = await grammarService.get<any>(
    landingGrammarUrls.GET_LANDING_GRAMMAR_AUTH_THEORY_LISTING,
    query
  );
  return data as any;
};

// timeInSeconds
export const updateTimeUserDoExercise = async ({ payload, id }) => {
  const { data } = await grammarService.put(
    landingGrammarUrls.UPDATE_TIME_USER_DO_EXERCISE,
    payload,
    {
      params: {
        id,
      },
    }
  );
  return data;
};

export const updateTimeUserDoTheory = async ({ payload, id }) => {
  const { data } = await grammarService.put(
    landingGrammarUrls.UPDATE_TIME_USER_DO_THEORY,
    payload,
    {
      params: {
        id,
      },
    }
  );
  return data;
};

export const startUserGrammarLesson = async ({ pageId }) => {
  const { data } = await grammarService.post(
    landingGrammarUrls.SAVE_START_USER_GRAMMAR_LESSON,
    {},
    {
      params: {
        pageId,
      },
    }
  );
  return data;
};

export const getTheoryDetailAfterStartUser = async (query) => {
  const { data } = await grammarService.get(
    landingGrammarUrls.GET_THEORIES_DATA_AFTER_START,
    query
  );
  return data;
};

export const getExerciseDetailAfterStartUser = async (query) => {
  const { data } = await grammarService.get(
    landingGrammarUrls.GET_EXERCISES_DATA_AFTER_START,
    query
  );
  return data;
};

export const getAllBlogsOfAuthorByAuthorIdPaged = async (query) => {
  const { data } = await publicGrammarService.get(
    landingGrammarUrls.GET_AUTHOR_BLOGS_LISTING,
    query
  );
  return data;
};

export const getAllBlogsOfAuthorByAuthorIdPagedForUser = async (query) => {
  const { data } = await grammarService.get(landingGrammarUrls.GET_AUTHOR_BLOGS_LISTING, query);
  return data;
};

export const getUserStatisticExercise = async () => {
  const { data } = await grammarService.get(landingGrammarUrls.GET_USER_EXERCISES_STATISTIC);
  return data;
};

export const getUserStatisticTheory = async () => {
  const { data } = await grammarService.get(landingGrammarUrls.GET_USER_THEORIES_STATISTIC);
  return data;
};

export const getUserExercisesHistoryListing = async (query) => {
  const { data } = await grammarService.get(
    landingGrammarUrls.GET_USER_EXERCISES_HISTORY_LISTING,
    query
  );
  return data;
};

export const getUserTheoriesHistoryListing = async (query) => {
  const { data } = await grammarService.get(
    landingGrammarUrls.GET_USER_THEORIES_HISTORY_LISTING,
    query
  );
  return data;
};

export const updateStatusAfterDoAllExercises = async ({ pageId }) => {
  const { data } = await grammarService.put(
    landingGrammarUrls.UPDATE_USER_STATUS_AFTER_DO_EXERCISE_BLOG,
    {},
    {
      params: {
        pageId,
      },
    }
  );
  return data;
};

export const updateConfirmReadTheory = async ({ pageId }) => {
  const { data } = await grammarService.put(
    landingGrammarUrls.UPDATE_CONFIRM_READ_THEORY,
    {},
    {
      params: {
        pageId,
      },
    }
  );
  return data;
};

export const updateUserExerciseProgressByPageId = async ({ payload, pageId }) => {
  const { data } = await grammarService.put(
    landingGrammarUrls.UPDATE_USER_EXERCISE_PROGRESS_BY_ID,
    payload,
    {
      params: {
        pageId,
      },
    }
  );
  return data;
};

export const updateViewTheoryNotAuthor = async ({ pageId }) => {
  const { data } = await publicGrammarService.put(
    landingGrammarUrls.UPDATE_VIEW_THEORY_NOT_AUTHOR,
    {},
    {
      params: {
        pageId,
      },
    }
  );
  return data;
};

export const updateViewTheoryHasAuthor = async ({ pageId }) => {
  const { data } = await grammarService.put(
    landingGrammarUrls.UPDATE_VIEW_THEORY_HAS_AUTHOR,
    {},
    {
      params: {
        pageId,
      },
    }
  );
  return data;
};
