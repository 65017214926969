import * as React from 'react';
import { GrammarLandingNotFound404 } from '@doltech/domains/landing-grammar/lib/other-static-pages/GrammarLandingNotFound404';
import { GrammarLandingPageLayout } from '@doltech/domains/landing-grammar/lib/landing/GrammarLandingPageLayout';
import { useQuery } from 'react-query';
import { getLandingGrammarPageByTemplateTypes } from '@doltech/domains/landing-grammar/lib/shared/apis/landing-grammar';
import { GrammarTemplateTypeList } from '@doltech/domains/landing-grammar/lib/shared/constants';

const PageNotFound = () => {
  const { data: otherUrlInfo } = useQuery<any>(['getLandingGrammarPageByTemplateTypes'],
    () => getLandingGrammarPageByTemplateTypes(GrammarTemplateTypeList), {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    });
  return (
    <GrammarLandingPageLayout otherUrlInfo={otherUrlInfo?.data}>
      <GrammarLandingNotFound404 otherUrlInfo={otherUrlInfo?.data}/>
    </GrammarLandingPageLayout>
  );
};

export default PageNotFound;
