import { environment } from '@doltech/core/lib/environment/environment';
import { setup } from '@doltech/core/lib/api/api.config';

export const publicGrammarService = setup({
  baseURL: environment.REACT_PUBLIC_GRAMMAR_API_HOSTNAME,
});

export const grammarService = setup({
  baseURL: environment.REACT_APP_GRAMMAR_API_HOSTNAME,
});
