import * as React from 'react';
import { ButtonSize } from '@doltech/ui/lib/figma/Button/ButtonSize/ButtonSize';
import { ButtonContentGeneral } from '@doltech/ui/lib/figma/Button/ButtonContentGeneral/ButtonContentGeneral';
import { ButtonStyles } from '@doltech/ui/lib/figma/Button/ButtonStyles/ButtonStyles';

interface SuggestionTopicButtonProps {
  href?: string;
  label?: string;
}

export const SuggestionNotFoundButton = (props: SuggestionTopicButtonProps) => {
  const { href, label} = props;
  return (
    <a href={href}>
      <ButtonStyles
        color="purple"
        buttonStyle="outline"
        transparent={false}
        colorStyle="matte"
        content={
          <ButtonSize
            size="large"
            verticalPadding
            content={
              <ButtonContentGeneral
                text={label}
              />
            }
          />
        }
      />
    </a>
  )
};
