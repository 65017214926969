export const landingGrammarUrls = {
  GET_LANDING_GRAMMAR_BY_TEMPLATE: '/seo-page',
  GET_LANDING_GRAMMAR_DETAIL_BY_ID: '/seo-page/:id',
  GET_LANDING_GRAMMAR_TOPIC_LISTING: '/seo-page/topics',
  GET_LANDING_GRAMMAR_THEORY_LISTING: '/seo-page/theories/paged', // topicId
  GET_LANDING_GRAMMAR_EXERCISE_LISTING: '/seo-page/exercises/paged', // topicId
  GET_LANDING_GRAMMAR_AUTHOR_LISTING: '/seo-page/authors/paged', // topicId, size

  GET_AUTHOR_BLOGS_LISTING: '/landing-blog/blogs-author/paged/:authorId',

  GET_LANDING_BLOG_HOME_SEO: '/landing-blog/home',
  GET_LANDING_GRAMMAR_THEORY_LATEST: '/landing-blog/theories/latest', // exerciseId,String topicId, int size
  GET_LANDING_GRAMMAR_EXERCISE_LATEST: '/landing-blog/exercises/latest', // String resourceId,String topicId, int size
  GET_SEO_PAGE_BY_URL: '/seo-page/by-url',
  GET_SEO_SCHEMA: '/seo-page/schema',


  GET_SEO_PAGE_DETAIL_BY_PAGE_ID: '/seo-page/detail/:pageId',

  GET_RELATE_EXERCISE_BY_THEORY: '/seo-page/relate-exercises/by-theory', // id

  GET_LANDING_GRAMMAR_AUTH_BY_TEMPLATE: '/user',
  GET_LANDING_GRAMMAR_RECENT_EXERCISE_POSTS: '/user/exercises/recent-posts',
  GET_LANDING_GRAMMAR_RECENT_THEORY_POSTS: '/user/theories/recent-posts',
  GET_LANDING_GRAMMAR_AUTH_EXERCISES: '/user/exercises',
  GET_LANDING_GRAMMAR_AUTH_THEORY: '/user/theories', //  exerciseId, status
  GET_LANDING_GRAMMAR_AUTH_EXERCISES_LISTING: '/landing-blog/exercises/paged',
  GET_LANDING_GRAMMAR_AUTH_THEORY_LISTING: '/landing-blog/theories/paged', // topicId, WorkStatusEnum status
  GET_LANDING_GRAMMAR_AUTH_EXERCISES_PROGRESS_LISTING: '/user/exercises/progress',
  GET_LANDING_GRAMMAR_AUTH_EXERCISES_STATISTIC_LISTING: '/user/exercises/statistic',
  GET_RELATE_THEORIES_BY_EXERCISE: '/landing-blog/relate-theories/by-exercise',
  GET_RELATE_EXERCISES_BY_THEORY: '/landing-blog/relate-exercises/by-theory',
  GET_RELATE_TOPICS_BY_THEORY: '/landing-blog/relate-topics/by-theory',
  GET_RELATE_TOPICS_BY_EXERCISE: '/landing-blog/relate-topics/by-exercise',

  GET_LANDING_GRAMMAR_AUTHOR_BLOGS_LISTING: '/authors/paged/by-author-id/:id',
  UPDATE_CONFIRM_READ_THEORY: '/user/theories/update-status/:pageId/finished',
  UPDATE_TIME_USER_DO_THEORY: '/user/theories/update-time/:id',
  SAVE_START_USER_GRAMMAR_LESSON: '/seo-page/:pageId/start',

  CREATE_USER_LANDING_GRAMMAR: '/user/exercises/create',

  UPDATE_TIME_USER_DO_EXERCISE: '/user/exercises/update-time/:id',
  UPDATE_USER_COMPLETE_TEST: '/user/exercises/:id/completed',
  UPDATE_USER_STATUS_AFTER_DO_EXERCISE_BLOG: '/user/exercises/update-status/:pageId/completed',
  UPDATE_USER_EXERCISE_PROGRESS_BY_ID: '/user/exercises/progress/update/:pageId',

  GET_USER_EXERCISES_PROGRESS: '/user/exercises/progress',

  GET_OTHER_COMPLETED_USERS_EXERCISE: '/user/exercises/others-completed-users',
  GET_OTHER_COMPLETED_USERS_THEORY: '/user/theories/others-completed-users',

  GET_THEORIES_DATA_AFTER_START: '/user/theories/detail/:pageId',
  GET_EXERCISES_DATA_AFTER_START: '/user/exercises/detail/:pageId',

  GET_USER_EXERCISES_STATISTIC: '/user/exercises/history/statistic',
  GET_USER_THEORIES_STATISTIC: '/user/theories/history/statistic',
  GET_USER_EXERCISES_HISTORY_LISTING: '/user/exercises/paged',
  GET_USER_THEORIES_HISTORY_LISTING: '/user/theories/paged',

  GET_ELASTIC_SEARCH_MULTI_SEARCH: '/elastic-search/multi-search',
  GET_ELASTIC_SEARCH_MOST_POPULAR: '/elastic-search/search/most-popular',

  UPDATE_VIEW_THEORY_NOT_AUTHOR: '/seo-page/update/view/:pageId',
  UPDATE_VIEW_THEORY_HAS_AUTHOR: '/user/theories/update/view/:pageId',

  // seo
  GET_SITEMAPS_BY_TEMPLATE_TYPE: '/seo-page/site-maps/by-template-type/:templateTypeId',
};
